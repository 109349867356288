<template>
  <div class="row">
    <div v-if="phoneModel" class="col-12 col-md-4 q-pa-xs">
      <form-builder :schema="phoneSchema" />
    </div>

    <div v-if="model" class="col-12 col-md">
      <form-builder :schema="schema" />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['phone', 'profile'],
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    phoneModel: {
      type: Object,
      default () {
        return {}
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    phoneType: {
      type: String,
      default () {
        return 'tel'
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    phoneSchema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: this.phoneType,
                maxlength: 255,
                field: 'phone',
                value: this.phoneModel.phone,
                disabled: this.disabled,
                label: this.$t('Phone'),
                wrapperStyleClasses: 'col-12',
                onChange: phone => {
                  this.$emit('phone', { ...this.phoneModel, phone })
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                disabled: this.disabled,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (name) => {
                  this.$emit('profile', { ...this.model, name })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'surname',
                value: this.model.surname,
                disabled: this.disabled,
                label: this.$t('Second name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (surname) => {
                  this.$emit('profile', { ...this.model, surname })
                }
              },
              {
                type: 'input',
                inputType: 'email',
                field: 'email',
                value: this.model.email,
                required: false,
                disabled: this.disabled,
                label: this.$t('E-mail'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (email) => {
                  this.$emit('profile', { ...this.model, email })
                }
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
